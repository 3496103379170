import { AnyAction } from 'redux';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import {
  GET_TESTER_INFO, GET_TESTER_WTA,
} from 'store/types/userInfo';

export const initialState = {
  loading: false,
  error: undefined,
  data: undefined,
  showWTA: false,
};

/**
 * User info reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, GET_TESTER_INFO):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, GET_TESTER_INFO):
      return {
        ...state,
        loading: false,
        data: action.data,
      };

    case buildStoreType(prefixes.failure, GET_TESTER_INFO):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case buildStoreType(prefixes.requesting, GET_TESTER_WTA):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, GET_TESTER_WTA):
      return {
        ...state,
        loading: false,
        showWTA: action.showWTA,
      };

    case buildStoreType(prefixes.failure, GET_TESTER_WTA):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
