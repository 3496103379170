import { AnyAction } from 'redux';
import { find, isArray, cloneDeep, isEmpty } from 'lodash';
import { ProductExtended } from 'components/Product/common/ProductEntity';
import {
  BLOCK, ERROR, FETCH_START, READY,
  RESET, PROCESS, SHIP_PRODUCT, SHIP_PRODUCT_RESET, STATISTIC, COMMENTS_FETCH_START,
  COMMENTS_READY, COMMENTS_ERROR, PRODUCT_LIST, PRODUCT_LIFECYCLE, PRODUCT_RESET_ERROR,
} from '../types/product';
import { buildStoreType } from 'utils';
import * as prefixes from '../types/prefixes';

/**
 * Initial store data.
 */
const initialState = {
  productStatistics: undefined,
  model: null,
  list: [],
  page: undefined,
  pending: false,
  error: null,
  shipError: undefined,
  shipSuccess: false,
  comments: {},
  lifecycleLoading: false,
  lastLyfecycle: undefined,
  lifecycleError: undefined,
};

/**
 * Product/Project store reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case STATISTIC:
      return { ...state, productStatistics: action.value };
    case FETCH_START:
      return { ...state, pending: true, error: null };
    case READY:
      return { ...state, pending: false, model: action.payload };
    case ERROR:
      return { ...state, pending: false, error: action.payload };
    case RESET:
      return { ...state, pending: false, error: null, model: null };
    case PROCESS:
      return { ...state, pending: (action.payload || false) };
    case PRODUCT_RESET_ERROR:
      return { ...state, error: null };
    case BLOCK:
      if (!state.model) {
        return state;
      }

      if (isArray(state.model)) {
        const list = (state.model || []).concat();

        const product: any = find(list, { id: action.payload.id });

        if (product) {
          product.blocked = action.payload.state;
        }

        return { ...state, model: list };
      }

      if (state.model) {
        const model = cloneDeep(state.model) as unknown as ProductExtended;
        model.blocked = action.payload.state;

        return { ...state, model };
      }

      return state;

    case buildStoreType(prefixes.requesting, SHIP_PRODUCT):
      return {
        ...state,
        shipError: undefined,
        pending: true,
        shipSuccess: false,
      };
    case buildStoreType(prefixes.success, SHIP_PRODUCT):
      return {
        ...state,
        pending: false,
        shipSuccess: true,
      };
    case buildStoreType(prefixes.failure, SHIP_PRODUCT):
      return {
        ...state,
        pending: false,
        shipError: action.error,
      };

    case SHIP_PRODUCT_RESET:
      return {
        ...state,
        pending: false,
        shipError: undefined,
        shipSuccess: false,
      };

    case COMMENTS_FETCH_START:
      {
        const { payload: testReportId } = action;
        const data  = (state.comments as any)[testReportId] || {};

        return {
          ...state,
          comments: {
            ...state.comments,
            [testReportId]: {
              ...data,
              commentsLoading: true,
            },
          },
        };
      }
    case COMMENTS_READY:
      {
        // expose variables
        const { testReportId, data: rawData } = action.payload;
        const data = isArray(rawData) ? rawData : (isEmpty(rawData) ? [] : [rawData]);

        return {
          ...state,
          comments: {
            ...state.comments,
            [testReportId]: {
              comments: data,
              commentsLoading: false,
              commentsError: null,
            },
          },
        };
      }
    case COMMENTS_ERROR:
      {
        // expose variables
        const { testReportId, error } = action.payload;

        return {
          ...state,
          comments: {
            ...state.comments,
            [testReportId]: {
              comments: [],
              commentsLoading: false,
              commentsError: error,
            },
          },
        };
      }

    case buildStoreType(prefixes.success, PRODUCT_LIST):
      return {
        ...state,
        pending: false,
        list: action.payload.products,
        page: action.payload.page,
      };

    case buildStoreType(prefixes.requesting, PRODUCT_LIFECYCLE):
      return {
        ...state,
        lifecycleLoading: true,
        lastLyfecycle: undefined,
        lifecycleError: null,
      };

    case buildStoreType(prefixes.success, PRODUCT_LIFECYCLE):
      return {
        ...state,
        lifecycleLoading: false,
        lastLyfecycle: action.payload,
      };

    case buildStoreType(prefixes.failure, PRODUCT_LIFECYCLE):
      return {
        ...state,
        lifecycleLoading: false,
        lifecycleError: action.error,
      };

    default:
      return state;
  }
};
