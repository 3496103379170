import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './Button.module.sass';

export type IButtonProps = {
  className?: string,
  value: ReactNode | string,
  disabled?: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  tertiary?: boolean,
  secondary?: boolean,
  type?: 'submit' | 'button' | 'reset',
};

export default function (props: IButtonProps) {
  const {
    className,
    value,
    onClick,
    disabled,
    tertiary,
    secondary,
    type = 'button',
  } = props;

  return (
    <button
      className={classnames(
        className,
        styles.container,
        {
          [styles.disabled]: disabled,
          [styles.tertiary]: tertiary,
          [styles.secondary]: secondary,
        },
      )}
      onClick={disabled ? undefined : onClick}
      type={type}
      disabled={disabled}
    >
      {value}
    </button>
  );
}
