import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentModal from 'components/DocumentModal';
import { getDocuments } from 'store/actions/document';
import { addModal } from 'store/actions/modal';
import { DocumentType } from 'utils';

import styles from './Footer.module.sass';

export default function Footer() {
  const dispatch = useDispatch();
  const { [DocumentType.FOOTER]: links = [] } = useSelector((state: any) => (
    state.document.documents || []
  ));

  const isFooterNecessary: boolean = !!links.length;

  useEffect(
    () => {
      dispatch(getDocuments());
    },
    [dispatch],
  );

  function onItemClick(id: number) {
    dispatch(addModal((
      <DocumentModal
        id={id}
        type={DocumentType.FOOTER}
      />
    )));
  }

  if (!isFooterNecessary) {
    return null;
  }

  return (
    <footer className={styles.container}>
      <ul className={styles.content}>
        {links.map((item: LinkItem) => renderLink(item, onItemClick))}
      </ul>
    </footer>
  );
}

type LinkItem = {
  id: number,
  name: string,
};

function renderLink({ id, name }: LinkItem, onDocumentClick: (id: number) => void) {
  function onItemClick() {
    onDocumentClick(id);
  }

  return (
    <li key={id} className={styles.item}>
      <button
        className={styles.link}
        onClick={onItemClick}
      >
        {name}
      </button>
    </li>
  );
}
