export const GET_DL_ATTRIBUTES: string = 'GET_DL_ATTRIBUTES';
export const GET_DL_ATTRIBUTE_VALUES: string = 'GET_DL_ATTRIBUTE_VALUES';
export const CREATE_DL: string = 'CREATE_DL';
export const DL_RESET: string = 'DL_RESET';
export const DL_RESET_ERROR: string = 'DL_RESET_ERROR';
export const GET_DL_LIST: string = 'GET_DL_LIST';
export const DELETE_DL_ENTRY: string = 'DELETE_DL_ENTRY';
export const UPDATE_DL_ENTRY: string = 'UPDATE_DL_ENTRY';
export const GET_DL_ENTRY: string = 'GET_DL_ENTRY';
export const GET_DL_DATES: string = 'GET_DL_DATES';
