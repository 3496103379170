import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import get from 'lodash/get';
import {
  buildStoreType,
  getConfig,
  clearAuthData,
  REFRESH_TOKEN_KEY,
  setAuthData,
  parseErrorDetails,
} from 'utils';
import * as prefixes from 'store/types/prefixes';
import {
  SIGN_IN, SIGN_OUT, SEND_EMAIL, RESET_STORE,
  CHANGE_PASSWORD, CHECK_TOKEN, RESET_ERRORS, REFRESH_TOKEN,
} from 'store/types/auth';
import {
  SIGN_IN as SIGN_IN_URL,
  SIGN_OUT as SIGN_OUT_URL,
  FORGOT_PASSWORD, CHANGE_PASSWORD as CHANGE_PASSWORD_URL, CHECK_UID,
  REFRESH_TOKEN as REFRESH_TOKEN_URL,
} from 'api/urls';
import ajax from 'api/ajax';
import i18n from 'locales/i18n';

/**
 * Sign in action
 */
export function signIn(username: string, password: string) {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, SIGN_IN),
    });

    try {
      const { brand } = getConfig();
      const { data } = await ajax.post(
        SIGN_IN_URL,
        { username, password, brand },
      );

      dispatch({
        data,
        type: buildStoreType(prefixes.success, SIGN_IN),
        resetPassword: false,
        successSignin: true,
      });
    } catch (error) {
      const { status, data } = get(error, 'response', {});

      if (status === 400) {
        dispatch({
          type: buildStoreType(prefixes.failure, SIGN_IN),
          error: data.title,
          validation: parseErrorDetails(data.errors),
          showNotification: false,
        });

        return;
      }

      dispatch({
        type: buildStoreType(prefixes.failure, SIGN_IN),
        error: i18n.t('errors.internal'),
        showNotification: true,
      });
    }
  };
}

/**
 * Send email for reset password action
 */
export function sendEmailForPasswordReset(email: string, brand: string) {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, SEND_EMAIL),
    });

    try {
      await ajax.post(FORGOT_PASSWORD, { email, brand });
      dispatch({
        type: buildStoreType(prefixes.success, SEND_EMAIL),
      });
    } catch (error) {
      const { response = {} } = error;
      const { status, data } = response;

      if (status === 400) {
        dispatch({
          type: buildStoreType(prefixes.failure, SEND_EMAIL),
          error: data.title,
          validation: parseErrorDetails(data.errors),
          showNotification: false,
        });

        return;
      }

      dispatch({
        type: buildStoreType(prefixes.failure, SIGN_IN),
        error: i18n.t('errors.internal'),
        showNotification: true,
      });
    }
  };
}

export function checkResetToken(uid: string) {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    if (!uid) {
      dispatch({
        type: CHECK_TOKEN,
        tokenConfirmed: false,
      });

      return;
    }

    try {
      const { data } = await ajax.post(CHECK_UID, { uid });

      if (data.valid) {
        dispatch({
          type: CHECK_TOKEN,
          tokenConfirmed: true,
        });
      } else {
        dispatch({
          type: CHECK_TOKEN,
          tokenConfirmed: false,
        });
      }
    } catch {
      dispatch({
        type: buildStoreType(prefixes.failure, CHANGE_PASSWORD),
        error: i18n.t('errors.internal'),
        showNotification: true,
      });
    }
  };
}

export function changePassword(uid: string, code: string, newPassword: string) {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, CHANGE_PASSWORD),
    });

    try {
      const { data } = await ajax.post(CHANGE_PASSWORD_URL, { uid, code, newPassword });

      dispatch({
        data,
        type: buildStoreType(prefixes.success, SIGN_IN),
        resetPassword: false,
        successSignin: true,
      });
    } catch (error) {
      dispatch({
        type: buildStoreType(prefixes.failure, CHANGE_PASSWORD),
        error: error.message,
      });

      const { response = {} } = error;
      const { status, data } = response;

      if (status === 502) {
        dispatch({
          type: buildStoreType(prefixes.failure, CHANGE_PASSWORD),
          error: i18n.t('errors.incorrectCredentials'),
          validation: i18n.t('errors.incorrectCredentials'),
          showNotification: false,
        });

        return;
      }

      if (status === 400) {
        dispatch({
          type: buildStoreType(prefixes.failure, CHANGE_PASSWORD),
          error: data.title,
          validation: parseErrorDetails(data.errors),
          showNotification: false,
        });

        return;
      }

      dispatch({
        type: buildStoreType(prefixes.failure, CHANGE_PASSWORD),
        error: i18n.t('errors.internal'),
        showNotification: true,
      });
    }
  };
}

/**
 * Sign out action
 */
export function signOut() {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, SIGN_OUT),
    });

    try {
      await ajax.post(SIGN_OUT_URL);
      dispatch({ type: RESET_STORE });
      clearAuthData();
      window.location.replace('/signin');
    } catch {
      dispatch({
        type: buildStoreType(prefixes.failure, SIGN_IN),
        error: i18n.t('errors.internal'),
        showNotification: true,
      });
    }
  };
}

/**
 * Clear error and set showNotification to false
 */
export function resetState() {
  return { type: RESET_STORE };
}

export function resetErrors() {
  return { type: RESET_ERRORS };
}

export function refreshToken() {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!REFRESH_TOKEN_KEY) {
      return;
    }

    const { data } = await ajax.post(REFRESH_TOKEN_URL, { refreshToken });
    setAuthData({
      ...data,
      refresh_token: refreshToken,
    });
    dispatch({ data, type: REFRESH_TOKEN });
  };
}
