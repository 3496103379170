import { AnyAction } from 'redux';
import get from 'lodash/get';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import {
  GET_DL_ATTRIBUTES, DL_RESET, DL_RESET_ERROR, GET_DL_ATTRIBUTE_VALUES, CREATE_DL, GET_DL_LIST,
  DELETE_DL_ENTRY, GET_DL_ENTRY, GET_DL_DATES, UPDATE_DL_ENTRY,
} from 'store/types/dailyLogs';

export const initialState = {
  loading: false,
  attributes: undefined,
  attachments: [],
  error: undefined,
  validation: {},
  values: {},
  list: undefined,
  page: undefined,
  logDate: undefined,
  logUpdatedOn: undefined,
  listLastUpdate: undefined,
  takenDates: new Set(),
  startDate: undefined,
};

/**
 * Daily Logs reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {

    //  GET_DL_ATTRIBUTES
    case buildStoreType(prefixes.requesting, GET_DL_ATTRIBUTES):
      return {
        ...state,
        loading: true,
        validation: {},
        error: undefined,
      };

    case buildStoreType(prefixes.success, GET_DL_ATTRIBUTES):
      return {
        ...state,
        loading: false,
        attributes: action.data,
      };

    case buildStoreType(prefixes.failure, GET_DL_ATTRIBUTES):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //  GET_DL_ATTRIBUTE_VALUES
    case buildStoreType(prefixes.requesting, GET_DL_ATTRIBUTE_VALUES): {
      const value = get(state, ['values', action.attributeId], {});

      return {
        ...state,
        values: {
          ...state.values,
          [action.attributeId]: {
            ...value,
            loading: true,
            error: undefined,
          },
        },
      };
    }

    case buildStoreType(prefixes.success, GET_DL_ATTRIBUTE_VALUES): {
      const value = get(state, ['values', action.attributeId], {});

      return {
        ...state,
        values: {
          ...state.values,
          [action.attributeId]: {
            ...value,
            loading: false,
            data: action.values,
            page: action.page,
          },
        },
      };
    }

    case buildStoreType(prefixes.failure, GET_DL_ATTRIBUTE_VALUES): {
      const value = get(state, ['values', action.attributeId], {});

      return {
        ...state,
        values: {
          ...state.values,
          [action.attributeId]: {
            ...value,
            loading: false,
            error: action.error,
          },
        },
      };
    }

    //  CREATE_DL
    case buildStoreType(prefixes.requesting, CREATE_DL):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, CREATE_DL):
      return {
        ...state,
        loading: false,
      };

    case buildStoreType(prefixes.failure, CREATE_DL):
      return {
        ...state,
        loading: false,
        validation: action.validation || {},
        error: action.error,
      };

    //  GET_DL_LIST
    case buildStoreType(prefixes.requesting, GET_DL_LIST):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, GET_DL_LIST):
      return {
        ...state,
        loading: false,
        list: action.list,
        listLastUpdate: action.listLastUpdate,
        page: action.page,
      };

    case buildStoreType(prefixes.failure, GET_DL_LIST):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //  DELETE_DL_ENTRY
    case buildStoreType(prefixes.requesting, DELETE_DL_ENTRY):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, DELETE_DL_ENTRY):
      return {
        ...state,
        loading: false,
      };

    case buildStoreType(prefixes.failure, DELETE_DL_ENTRY):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //  GET_DL_ENTRY
    case buildStoreType(prefixes.requesting, GET_DL_ENTRY):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, GET_DL_ENTRY):
      return {
        ...state,
        loading: false,
        logDate: action.logDate,
        logUpdatedOn: action.logUpdatedOn,
        attributes: action.attributes,
        attachments: action.attachments,
      };

    case buildStoreType(prefixes.failure, GET_DL_ENTRY):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //  UPDATE_DL_ENTRY
    case buildStoreType(prefixes.requesting, UPDATE_DL_ENTRY):
      return {
        ...state,
        loading: true,
        error: undefined,
        validation: {},
      };

    case buildStoreType(prefixes.success, UPDATE_DL_ENTRY):
      return {
        ...state,
        loading: false,
        logDate: action.logDate,
        attributes: action.attributes,
        attachments: action.attachments,
      };

    case buildStoreType(prefixes.failure, UPDATE_DL_ENTRY):
      return {
        ...state,
        loading: false,
        error: action.error,
        validation: action.validation || {},
      };

    case GET_DL_DATES:
      return {
        ...state,
        takenDates: action.dates.takenDates,
        startDate: action.dates.startDate,
      };

    case DL_RESET:
      return {
        ...initialState,
      };

    case DL_RESET_ERROR:
      return {
        ...state,
        error: undefined,
      };

    default:
      return state;
  }
};
