import React, { lazy } from 'react';
import LazyLoadWrapper from 'components/LazyLoadWrapper';

const ChangeLog = lazy(() => import('pages/ChangeLog'));

export default function (props: any) {
  return (
    <LazyLoadWrapper>
      <ChangeLog {...props} />
    </LazyLoadWrapper>
  );
}
