import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { DocumentType } from 'utils';
import styles from './FullSizeContainer.module.sass';

interface IFullSizeContainerProps {
  children: React.ReactNode;
}

export default function ({ children }: IFullSizeContainerProps) {
  const { [DocumentType.FOOTER]: links = [] } = useSelector((state: any) => (
    state.document.documents || []
  ));

  const isFooterNecessary: boolean = !!links.length;

  return (
    <div
      className={classnames(styles.container, {
        [styles.withFooter]: isFooterNecessary,
      })}
    >
      {children}
    </div>
  );
}
