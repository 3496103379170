import React, { lazy } from 'react';
import LazyLoadWrapper from 'components/LazyLoadWrapper';

const SigninComponent = lazy(() => import('pages/Auth/Signin'));

export function Signin(props: any) {
  return (
    <LazyLoadWrapper>
      <SigninComponent {...props} />
    </LazyLoadWrapper>
  );
}

const PasswordResetComponent = lazy(() => import('pages/Auth/PasswordReset'));

export function PasswordReset(props: any) {
  return (
    <LazyLoadWrapper>
      <PasswordResetComponent {...props} />
    </LazyLoadWrapper>
  );
}

const EnterEmailComponent = lazy(() => import('pages/Auth/EnterEmail'));

export function EnterEmail(props: any) {
  return (
    <LazyLoadWrapper>
      <EnterEmailComponent {...props} />
    </LazyLoadWrapper>
  );
}
