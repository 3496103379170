import { AnyAction } from 'redux';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import {
  ADD_ACTIVITY,
  CLEAR_ACTIVITY_TO_ADD, GET_ACCESSORIES_VALUES,
  GET_ACTIVITY,
  GET_ACTIVITY_ATTR_VALUES,
  GET_ADDITION_ACTIVITIES, REMOVE_ACTIVITY,
  SELECT_ACTIVITY_TO_ADD, UPDATE_ACTIVITY,
} from 'store/types/activity';

export const initialState = {
  loading: false,
  sportCategories: [],
  error: null,
  updating: false,
  attributeValuesLoading: false,
  activityAttributeValues: {},
};

/**
 * Activity reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, GET_ACTIVITY):
      return {
        ...state,
        loading: true,
        error: null,
      };

    case buildStoreType(prefixes.success, GET_ACTIVITY):
      return {
        ...state,
        loading: false,
        sportCategories: action.sportCategories,
      };

    case buildStoreType(prefixes.failure, GET_ACTIVITY):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case buildStoreType(prefixes.requesting, GET_ADDITION_ACTIVITIES):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case buildStoreType(prefixes.success, GET_ADDITION_ACTIVITIES):
      return {
        ...state,
        loading: false,
        activityList: action.activityList,
      };
    case buildStoreType(prefixes.failure, GET_ADDITION_ACTIVITIES):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case buildStoreType(prefixes.requesting, SELECT_ACTIVITY_TO_ADD):
      return {
        ...state,
        activityToAdd: action.activityToAdd,
        loading: true,
        error: null,
      };
    case buildStoreType(prefixes.success, SELECT_ACTIVITY_TO_ADD):
      return {
        ...state,
        activityToAdd: action.activityToAdd,
        loading: false,
        activityAttributes: action.activityAttributes,
      };
    case buildStoreType(prefixes.failure, SELECT_ACTIVITY_TO_ADD):
      return {
        ...state,
        activityToAdd: action.activityToAdd,
        loading: false,
        error: action.error,
      };

    // GET_ACTIVITY_ATTR_VALUES
    case buildStoreType(prefixes.requesting, GET_ACTIVITY_ATTR_VALUES):
      return {
        ...state,
        attributeValuesLoading: true,
        error: null,
      };
    case buildStoreType(prefixes.success, GET_ACTIVITY_ATTR_VALUES):
      return {
        ...state,
        activityAttributeValues: action.activityAttributeValues,
        attributeValuesLoading: false,
      };
    case buildStoreType(prefixes.failure, GET_ACTIVITY_ATTR_VALUES):
      return {
        ...state,
        attributeValuesLoading: false,
        error: action.error,
      };

    // GET ACCESSORIES VALUES
    case buildStoreType(prefixes.requesting, GET_ACCESSORIES_VALUES):
      return {
        ...state,
        attributeValuesLoading: true,
        error: null,
      };
    case buildStoreType(prefixes.success, GET_ACCESSORIES_VALUES):
      return {
        ...state,
        attributeValuesLoading: false,
        activityAccessoriesValues: action.activityAccessoriesValues,
      };
    case buildStoreType(prefixes.failure, GET_ACCESSORIES_VALUES):
      return {
        ...state,
        attributeValuesLoading: false,
        error: action.error,
      };

    //  ADD_ACTIVITY
    case buildStoreType(prefixes.requesting, ADD_ACTIVITY):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case buildStoreType(prefixes.success, ADD_ACTIVITY):
      const sportCategories = [action.newSportCategory, ...state.sportCategories];

      return {
        ...state,
        sportCategories,
        loading: false,
      };
    case buildStoreType(prefixes.failure, ADD_ACTIVITY):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //  CLEAR_ACTIVITY_TO_ADD
    case buildStoreType(prefixes.success, CLEAR_ACTIVITY_TO_ADD):
      return {
        ...state,
        activityToAdd: undefined,
        activityAttributes: [],
        loading: false,
      };

    //  REMOVE_ACTIVITY
    case buildStoreType(prefixes.requesting, REMOVE_ACTIVITY):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case buildStoreType(prefixes.success, REMOVE_ACTIVITY):
      const newActivities: any =
        state.sportCategories.filter((activity: any) => activity.id === action.removeActivity);

      return {
        ...state,
        sportCategories: newActivities,
        loading: false,
      };
    case buildStoreType(prefixes.failure, REMOVE_ACTIVITY):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case buildStoreType(prefixes.requesting, UPDATE_ACTIVITY):
      return {
        ...state,
        loading: true,
        error: null,
        updating: true,
      };
    case buildStoreType(prefixes.success, UPDATE_ACTIVITY):
      return {
        ...state,
        loading: false,
        updating: false,
      };
    case buildStoreType(prefixes.failure, UPDATE_ACTIVITY):
      return {
        ...state,
        loading: false,
        updating: false,
        error: action.error,
      };
    default:
      return state;
  }
};
