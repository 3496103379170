import { AnyAction } from 'redux';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import {
  SIGN_IN, SIGN_OUT, SEND_EMAIL, CHANGE_PASSWORD,
  RESET_STORE, CHECK_TOKEN, RESET_ERRORS, REFRESH_TOKEN,
} from 'store/types/auth';

export const initialState = {
  loading: false,
  error: undefined,
  validation: undefined,
  resetPassword: false,
  successSignin: false,
  data: undefined,
  emailHasBeenSent: false,
  showNotification: false,
  successPasswordChange: false,

  tokenConfirmed: false,
  tokenHasBeenChecked: false,
};

/**
 * Auth reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, SIGN_IN):
      return {
        ...state,
        loading: true,
        error: undefined,
        showNotification: false,
        validation: undefined,
      };

    case buildStoreType(prefixes.success, SIGN_IN):
      return {
        ...state,
        loading: false,
        resetPassword: action.resetPassword,
        successSignin: action.successSignin,
        data: action.data,
      };

    case buildStoreType(prefixes.failure, SIGN_IN):
      return {
        ...state,
        loading: false,
        error: action.error,
        validation: action.validation,
        showNotification: action.showNotification,
      };

    case buildStoreType(prefixes.requesting, SIGN_OUT):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.failure, SIGN_OUT):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case buildStoreType(prefixes.requesting, SEND_EMAIL):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, SEND_EMAIL):
      return {
        ...state,
        loading: false,
        emailHasBeenSent: true,
        validation: undefined,
      };

    case buildStoreType(prefixes.failure, SEND_EMAIL):
      return {
        ...state,
        loading: false,
        error: action.error,
        validation: action.validation,
      };

    case buildStoreType(prefixes.requesting, CHANGE_PASSWORD):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, CHANGE_PASSWORD):
      return {
        ...state,
        loading: false,
        successPasswordChange: true,
      };

    case buildStoreType(prefixes.failure, CHANGE_PASSWORD):
      return {
        ...state,
        loading: false,
        validation: action.validation,
        error: action.error,
        showNotification: action.showNotification,
      };

    case RESET_STORE:
      return {
        ...initialState,
      };

    case RESET_ERRORS:
      return {
        ...state,
        showNotification: false,
        validation: undefined,
        error: undefined,
      };

    case CHECK_TOKEN:
      return {
        ...state,
        tokenConfirmed: action.tokenConfirmed,
        tokenHasBeenChecked: true,
      };

    case REFRESH_TOKEN:
      return {
        ...state,
        data: {
          ...state.data || {},
          ...action.data,
        },
      };

    default:
      return state;
  }
};
