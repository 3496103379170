import { AnyAction } from 'redux';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import {
  RELEASES_GET_LIST, RELEASES_GET_NEW, RELEASES_MARK_AS_READ, RELEASES_RESET_ERRORS,
} from 'store/types/releases';

export const initialState = {
  loading: false,
  new: {},
  list: [],
  error: undefined,
};

/**
 * Releases reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, RELEASES_GET_NEW):
      return {
        ...state,
        new: {
          ...state.new,
          loading: true,
          content: undefined,
          error: undefined,
        },
      };

    case buildStoreType(prefixes.success, RELEASES_GET_NEW):
      return {
        ...state,
        new: {
          content: action.new,
          page: action.page,
        },
      };

    case buildStoreType(prefixes.failure, RELEASES_GET_NEW):
      return {
        ...state,
        new: {
          ...state.new,
          loading: false,
          error: action.error,
        },
      };

    case buildStoreType(prefixes.requesting, RELEASES_MARK_AS_READ):
      return {
        ...state,
        loading: true,
      };

    case buildStoreType(prefixes.success, RELEASES_MARK_AS_READ):
      return {
        ...state,
        loading: false,
        new: {},
      };

    case buildStoreType(prefixes.failure, RELEASES_MARK_AS_READ):
      return {
        ...state,
        loading: false,
        new: {},
      };

    case buildStoreType(prefixes.requesting, RELEASES_GET_LIST):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, RELEASES_GET_LIST):
      return {
        ...state,
        loading: false,
        list: action.list,
        page: action.page,
      };

    case buildStoreType(prefixes.failure, RELEASES_GET_LIST):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case RELEASES_RESET_ERRORS:
      return {
        ...state,
        error: undefined,
        new: {
          ...state.new,
          error: undefined,
        },
      };

    default:
      return state;
  }
};
