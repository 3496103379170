import { AnyAction } from 'redux';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import { GET_FACILITY_STATISTIC, GET_FACILITY_TESTERS } from 'store/types/facilityManager';

export const initialState = {
  loading: false,
  statistic: undefined,
  error: undefined,
  showNotification: false,

  facilityUsers: {
    loading: false,
    data: [],
    page: undefined,
    error: undefined,
  },
};

/**
 * Auth reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, GET_FACILITY_STATISTIC):
      return {
        ...state,
        loading: true,
        showNotification: false,
        error: undefined,
      };

    case buildStoreType(prefixes.success, GET_FACILITY_STATISTIC):
      const newState = {
        ...state,
        loading: false,
      };

      if (!isEqual(action.data, state.statistic)) {
        newState.statistic = action.data;
      }

      return newState;

    case buildStoreType(prefixes.failure, GET_FACILITY_STATISTIC):
      return {
        ...state,
        loading: false,
        error: action.error,
        showNotification: action.showNotification,
      };

    case buildStoreType(prefixes.requesting, GET_FACILITY_TESTERS):
      return {
        ...state,
        facilityUsers: {
          ...state.facilityUsers,
          error: undefined,
          loading: true,
        },
      };

    case buildStoreType(prefixes.success, GET_FACILITY_TESTERS): {
      const currentPage = get(state.facilityUsers.page, 'page.number', 0);
      const receivedPage = get(action, 'page.number', 0);
      let data = action.data;

      // check if we receive next page, otherwise we just store data from API without concat
      if (receivedPage - currentPage === 1) {
        data = [...state.facilityUsers.data, ...action.data];
      }

      return {
        ...state,
        facilityUsers: {
          ...state.facilityUsers,
          data,
          loading: false,
          page: action.page,
        },
      };
    }

    case buildStoreType(prefixes.failure, GET_FACILITY_TESTERS):
      return {
        ...state,
        facilityUsers: {
          ...state.facilityUsers,
          error: action.error,
          loading: false,
        },
      };

    default:
      return state;
  }
};
