export const SIGN_IN: string = '/account/signin';
export const REFRESH_TOKEN: string = '/account/refresh';
export const SIGN_OUT: string = '/account/signout';
export const FORGOT_PASSWORD: string = '/account/password/forgot';
export const CHANGE_PASSWORD: string = '/account/password/change';
export const CHECK_UID: string = '/account/password/change/check-uid';

export const PROFILE: string = '/data/tester/';
export const TESTER_INFO: string = `${PROFILE}info`;
export const TESTER_SHOW_WTA: string = `${PROFILE}needToShowWTA/{testerId}`;
export const ACTIVITY: string = `${PROFILE}activity/sport-categories/`;
export const ADDITION_ACTIVITIES: string = `${PROFILE}activity/additional-activity/`;
export const SPORT_ATTRIBUTES: string = `${PROFILE}activity/{activityId}/sport-attribute`;
export const SPORT_ATTRIBUTE_VALUES : string =
  `${PROFILE}activity/attribute/{attributeId}/sport-attribute-values`;
export const SPORT_ACCESSORIES_VALUES : string =
  `${PROFILE}activity/accessories-values`;
export const EDIT_SPORT_ACTIVITIES: string = `${PROFILE}activity/sport-activities`;
export const PRODUCT: string = '/data/tester/test';
export const PRODUCT_STATISTIC: string = `${PRODUCT}/status/count`;
export const SHIP_PRODUCT: string = `${PRODUCT}/ship`;
export const DELETE_SPORT_ACTIVITY: string = `${PROFILE}activity/{activityId}/sport-activities`;
export const PRODUCT_LIFECYCLES: string = `${PRODUCT}/lifecycle`;

export const DOCUMENTS: string = '/data/documents/{brand}';
export const DOCUMENT: string = `${DOCUMENTS}/{type}/{documentId}`;

export const FACILITY_STATISTIC: string = '/data/facility-manager/tests/count';
export const FACILITY_TESTERS: string = '/data/facility-management/{facilityId}/testers';
export const MODELS_BY_STATUS: string = '/data/facility-manager/tests/{status}/models';
export const SAMPLES_BY_MODEL: string =
  '/data/facility-manager/tests/{status}/models/{modelNumber}/samples';
export const SAMPLE_ASSIGN: string =
  '/data/facility-management/samples/report/{reportId}/tester/{testerId}';
export const SAMPLE_START_TEST: string =
  '/data/facility-management/samples/report/{reportId}/start';
export const SAMPLE_SHIP: string = '/data/facility-management/samples/ship';

export const PRODUCT_IMAGE: string = '/api/data/tester/test/product/{name}/image';

export const DAILY_LOG_ATTRIBUTES: string = '/daily-logs/test/{testReportId}/attributes';
export const DAILY_LOG_ATTRIBUTES_VALUES: string = '/daily-logs/test/{testReportId}/attributes/{attributeId}/values';
export const DAILY_LOG_RECORDS: string = '/daily-logs/test/{testReportId}/records';
export const DAILY_LOG_RECORD: string = '/daily-logs/test/{testReportId}/records/{logId}';
export const DAILY_LOG_TAKEN_DATES: string = '/daily-logs/test/{testReportId}/taken-dates';

export const RELEASES: string = '/data/releases';
export const RELEASES_NEW: string = `${RELEASES}/new`;

export const ATTACHMENT: string = '/document/attachment';
export const ATTACHMENT_DOWNLOAD: string = `${ATTACHMENT}/{attachmentId}/download`;
export const ATTACHMENT_PROPERTIES: string = `${ATTACHMENT}/properties`;

/**
 * Convert parametrized url.
 *
 * example:
 * url = /user/{userid}
 * props = {userid: 1}
 * result = /user/1
 *
 * @param sourceString - string with parameters
 * @param propsObject - object with params for url
 */
export function interpolateString(sourceString: string, propsObject: InterpolationParams) {
  return sourceString.replace(/{(\w+)}/g, (match, expr) => `${propsObject[expr] || ''}`);
}

interface InterpolationParams {
  [key: string]: string | number;
}
