import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addModal } from 'store/actions/modal';
import MenuWrapper from 'components/Menu/MenuWrapper';

import styles from './Header.module.sass';

/**
 * Header.
 */
function HeaderMobile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function onMenuClick() {
    dispatch(addModal((
      <MenuWrapper />
    )));
  }

  return (
    <div className={styles.headerMobile}>
      <button
        onClick={onMenuClick}
        className={styles.menu}
        type="button"
      >
        <Icon name="menu" />
      </button>

      <h5 className={styles.heading}>
        {t('general.header.title')}
      </h5>
    </div>
  );
}

export default HeaderMobile;
