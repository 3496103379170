import React from 'react';

import styles from './ChangeLogViewer.module.sass';

type FeatureType = {
  title: string;
  details: string;
};

interface IChangeLogViewerProps {
  features: FeatureType[];
}

export default function ChangeLogViewer({ features }: IChangeLogViewerProps) {
  return (
    <>
      {features.map((feature: FeatureType, i: number) => (
        <article key={i} className={styles.container}>
          <h6 className={styles.header}>{feature.title}</h6>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: feature.details,
            }}
          />
        </article>
      ))}
    </>
  );
}
