import { ReactNode } from 'react';
import { AnyAction } from 'redux';

import * as types from 'store/types/modal';

export const initialState = {
  components: [] as ReactNode[],
};

/**
 * ModalOverlay window reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.ADD_MODAL:
      return {
        ...state,
        components: [action.component, ...state.components],
      };

    case types.CLOSE_MODAL:
      return {
        ...state,
        components: state.components.slice(1),
      };

    case types.REPLACE_MODAL:
      return {
        ...state,
        components: [action.component, ...state.components.slice(1)],
      };

    default:
      return state;
  }
};
