import React from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';
import DocumentModal from 'components/DocumentModal';
import { getConfig, Brands, DocumentType } from 'utils';
import { PRODUCT_ROUTE_URL } from 'pages/Product/config';
import { replaceModal } from 'store/actions/modal';
import Logout from './Logout';

import styles from './Menu.module.sass';
import { PROFILE_ROUTE_URL } from 'pages/Profile/config';

interface IMenuProps {
  closing: boolean;
  triggerClose: () => void;
}

type SecureLink = {
  id: number,
  name: string,
};

const swipeableConfig: any = {
  preventDefaultTouchmoveEvent: true,
  trackMouse: true,
};

const menuMap: any = {
  [Brands.ADIDAS]: {
    site: 'https://adidas.com',
    contact: 'mailto:atp.biz.support@adidas.com',
    faq: 'https://adidasatp.eu.qualtrics.com/jfe/form/SV_1BIOA6xpQ6H8O7X',
    measure: 'https://youtu.be/8H0XoTy_PzM',
  },
  [Brands.REEBOK]: {
    site: 'https://reebok.com',
    contact: 'mailto:producttesting@reebok.com',
  },
};

export default function ({ closing, triggerClose }: IMenuProps) {
  const { t } = useTranslation();
  const brand: keyof typeof Brands = getConfig().brand;
  const dispatch = useDispatch();
  const { [DocumentType.MENU]: links = [] } = useSelector((state: any) => (
    state.document.documents || {}
  ));

  const swipeHandlers = useSwipeable({ onSwipedLeft: triggerClose, ...swipeableConfig });

  function onNavClick(event: React.MouseEvent) {
    const { target, currentTarget } = event;
    if (target === currentTarget) {
      event.stopPropagation();
    }
  }

  function onItemClick(id: number) {
    dispatch(replaceModal((
      <DocumentModal
        id={id}
        type={DocumentType.MENU}
      />
    )));
  }

  const currentBrand: string = Brands[brand];

  return (
    <nav
      className={classnames(styles.navbar, {
        [styles.closing]: closing,
      })}
      {...swipeHandlers}
      onClick={onNavClick}
    >
      <ul className={styles.list}>
        <li className={styles.list_item}>
          <Link to={PROFILE_ROUTE_URL} className={styles.link}>
            {t('menu.userProfile')}
          </Link>
        </li>

        <li className={styles.list_item}>
          <Link to={PRODUCT_ROUTE_URL} className={styles.link}>
            {t('menu.userTests')}
          </Link>
        </li>

        {links.map((item: SecureLink) => renderLink(item, onItemClick))}

        {brand === Brands.ADIDAS && (
          <li className={styles.list_item}>
            <a
              href={menuMap[currentBrand].measure}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('menu.measure')}
            </a>
          </li>
        )}

        <li className={styles.list_item}>
          <a
            href={menuMap[currentBrand].site}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${brand}.com`}
          </a>
        </li>

        {brand !== Brands.ADIDAS && (
          <li className={styles.list_item}>
            <Link to="/change-log" className={styles.link}>
              {t('menu.changeLog')}
            </Link>
          </li>
        )}

        {brand === Brands.ADIDAS && (
          <li className={styles.list_item}>
            <a
              href={menuMap[currentBrand].faq}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('menu.faq')}
            </a>
          </li>
        )}

        {brand !== Brands.ADIDAS && (
          <li className={styles.list_item}>
            <a
              href={menuMap[currentBrand].contact}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('email contact')}
            </a>
          </li>
        )}

        <li className={styles.list_item}><Logout /></li>
      </ul>
    </nav>
  );
}

function renderLink({ id, name }: SecureLink, onDocumentClick: (id: number) => void) {
  function onItemClick() {
    onDocumentClick(id);
  }

  return (
    <li key={id} className={styles.list_item}>
      <button
        className={styles.link}
        onClick={onItemClick}
      >
        {name}
      </button>
    </li>
  );
}
