import React, { lazy } from 'react';
import LazyLoadWrapper from 'components/LazyLoadWrapper';

const ProductComponent = lazy(() => import('pages/Product/Product'));

/**
 * Lazy loading wrapper.
 *
 * @param props External component properties.
 *
 * @author Oleh Duleba <oleh.duleba@external.adidas.com>
 */
export default function Product(props: any) {
  return (
    <LazyLoadWrapper>
      <ProductComponent {...props} />
    </LazyLoadWrapper>
  );
}
