import React, { Suspense } from 'react';
import { Provider } from 'react-redux';

import store from 'store';
import Router from 'routing/Router';

/**
 * Application.
 */
export default function App() {
  return (
    <Suspense fallback="Loading...">
      <Provider store={store}>
        <Router />
      </Provider>
    </Suspense>
  );
}
