import { ReactNode } from 'react';
import { AnyAction } from 'redux';
import * as types from '../types/modal';

export function addModal(component: ReactNode): AnyAction {
  return {
    component,
    type: types.ADD_MODAL,
  };
}

export function closeModal(): AnyAction {
  return { type: types.CLOSE_MODAL };
}

export function replaceModal(component: ReactNode): AnyAction {
  return {
    component,
    type: types.REPLACE_MODAL,
  };
}
