import React, { PureComponent, createRef } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import Icon from 'components/shared/Icon/Icon';
import i18n from 'locales/i18n';
import Spinner from '../Spinner';

import styles from './Select.module.sass';

export type IOption = {
  id: string | number,
  text: string | number,
};

type ISelectProps = {
  // container styles
  className?: string,

  // input container styles
  inputClassName?: string,

  // label styles
  labelClassName?: string,

  // value of select, for single - object, for multi - array of objects
  value?: IOption | IOption[],

  // placeholder of empty select
  placeholder?: string,

  // array of objects - options of select
  options?: IOption[],

  // label for select
  label?: string,

  // if true - label will be placed to the left of select, otherwise - to the top
  horizontal?: boolean,

  // if true - locks a select from any updates
  disabled?: boolean,

  // name of input
  name?: string,

  // loading statement for showing spinner while options are loading
  loading?: boolean,

  // if true - enable multiselect mode
  multi?: boolean,

  // number of miliseconds - time before onInputChange will be called
  throttle?: number,

  // offset for options scroll end
  optionsEndOffset?: number

  // callback for options scroll reach end
  onOptionsEnd?: (term?: string) => void,

  // onBlur callback for input
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,

  // callback which is called every time when value of select has been changed
  onChange?: (value: IOption | IOption[]) => void,

  // callback which is called when input is changed
  onInputChange?: (value: string) => void,

  // callback which is called when select is focused
  onSelectFocus?: () => void,

  // if provided - user can see cross icon (use it if you want clear data from select by one click)
  onClear?: () => void,
  required?: boolean,
};

type ISelectState = {
  // flag for options list to be shown
  showOptions: boolean,

  // select input value for autocomplete
  input: string,

  // options which will be shown (filtered in case of multi - true)
  options?: IOption[],
};

export default class Select extends PureComponent<ISelectProps, ISelectState> {

  private containerElement = createRef<HTMLDivElement>();
  private inputElement = createRef<HTMLInputElement>();
  // throttle timeout id
  private inputTimeoutId: number;

  static getDerivedStateFromProps(props: ISelectProps, state: ISelectState) {
    // if we have a single select or options don't changed - keep working with prev options in state
    if (props.multi) {
      return {
        options: Select.filterOptions(props.options || [], props.value),
      };
    }

    return {
      options: props.options,
    };
  }

  /**
   * static method to use it inside of getDerivedStateFromProps callback
   * method receives a list of options and current value and then returns
   * list of options without value items
   * @param options select options list
   * @param value select value
   * @returns filtered options list
   */
  private static filterOptions(options: IOption[], value?: IOption | IOption[]) {
    if (!value) return [...options];
    const multipleValue = Array.isArray(value);

    return options.filter((option: IOption) => {
      if (multipleValue) {
        // @ts-ignore
        return !value.some((v: IOption) => v.id.toString() === option.id.toString());
      }

      // @ts-ignore
      return value.id.toString() !== option.id.toString();
    });
  }

  public constructor(props: ISelectProps) {
    super(props);

    this.state = {
      showOptions: false,
      input: '',
      options: props.multi ? Select.filterOptions(props.options || [], props.value) : props.options,
    };
  }

  public componentWillUnmount() {
    // remove click handler for options dropdown
    // and clear throttle timeout
    document.removeEventListener('click', this.onWindowClick);
    clearTimeout(this.inputTimeoutId);
  }

  public render() {
    const {
      className,
      labelClassName,
      inputClassName,
      label,
      horizontal,
      disabled,
      loading,
      multi,
      required,
    } = this.props;

    return (
      <div
        className={classnames(styles.container, className, {
          [styles.horizontal]: horizontal,
          [styles.disabled]: disabled,
          [styles.loading]: loading,
          [styles.multi]: multi,
        })}
      >
        {label && (
          <span className={classnames(styles.label, labelClassName)}>
            {label}

            {required && (
              <>
                &nbsp;
                <span className={styles.required}>*</span>
              </>
            )}
          </span>
        )}

        <div
          ref={this.containerElement}
          className={classnames(styles.content, inputClassName, {
            [styles.focus]: this.state.showOptions,
          })}
        >
          {this.state.showOptions
            ? this.renderEditMode()
            : this.renderReadMode()
          }
        </div>
      </div>
    );
  }

  private renderReadMode() {
    const {
      onClear,
      placeholder,
      disabled,
      multi,
    } = this.props;
    const text = this.getTextValue();

    return (
      <button
        type="button"
        className={classnames(styles.input_read, {
          [styles.placeholder]: !text,
        })}
        onClick={disabled ? undefined : this.onInputClick}
        disabled={disabled}
      >
        <span className={styles.text}>
          {text || placeholder}
        </span>

        {onClear && text && (
          <span className={styles.clear} onClick={onClear}>
            <Icon name="close" />
          </span>
        )}

        {!disabled && !multi && (
          <Icon name="arrow-dropdown-down" />
        )}
      </button>
    );
  }

  private renderEditMode() {
    const {
      name,
      loading,
      onBlur,
      value,
      multi,
      onOptionsEnd,
    } = this.props;

    const { showOptions, options } = this.state;

    let selected: string | number;
    if (value && !Array.isArray(value)) {
      selected = value.id;
    }

    return (
      <div
        className={styles.input_container}
        onClick={this.onInputContainerClick}
      >
        {multi && this.renderMultiTags()}

        <input
          ref={this.inputElement}
          className={styles.input}
          type="text"
          name={name}
          value={this.state.input}
          onChange={this.onInputChange}
          onKeyDown={multi ? this.handleBackspace : undefined}
          onBlur={onBlur}
          autoComplete="off"
          autoFocus
        />

        {showOptions && loading && (
          <Spinner className={styles.spinner} />
        )}

        {showOptions && (
          <ul
            className={styles.options}
            onScroll={onOptionsEnd ? this.onOptionsScroll : undefined}
          >
            {options && options.length > 0
              ? options.map((item, i: number) => (
                <li
                  key={item.id}
                  className={classnames(styles.option, {
                    [styles.selected]: item.id === selected,
                  })}
                >
                  <button
                    onClick={loading ? undefined : this.onOptionClick}
                    data-index={i}
                    type="button"
                  >
                    {item.text}
                  </button>
                </li>
              ))
              : <li className={styles.emptyOptions}>{i18n.t('emptySelectOptions')}</li>
            }
          </ul>
        )}
      </div>
    );
  }

  private renderMultiTags() {
    const { value } = this.props;

    if (!value) return null;

    if (Array.isArray(value)) {
      return value.map(this.renderTag);
    }

    return this.renderTag(value);
  }

  private renderTag = (value: IOption) => {
    return (
      <div key={value.id} className={styles.tag}>
        {value.text}

        <button
          onClick={this.handleTagClose(value.id)}
          type="button"
        >
          <Icon name="close" />
        </button>
      </div>
    );
  }

  private handleTagClose(id: string | number) {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      event.nativeEvent.stopImmediatePropagation();

      const { value, onChange } = this.props;

      if (!value || !onChange) { return; }

      if (Array.isArray(value)) {
        const newValue = value.filter(v => v.id !== id);
        this.setState({
          options: Select.filterOptions(this.props.options || [], newValue),
        });
        onChange(newValue);
      }
    };
  }

  private handleBackspace = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { onChange, value } = this.props;
    if (this.state.input || !onChange || !value) { return; }

    if (event.key === 'Backspace' && Array.isArray(value) && value.length > 0) {
      // @ts-ignore
      const input: string = value[value.length - 1].text;
      this.setState({ input });
      onChange(value.slice(0, value.length - 1));
    }
  }

  private onOptionClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.nativeEvent.stopImmediatePropagation();

    const { onChange, multi, value = [] } = this.props;
    const { options } = this.state;
    const { index = '0' } = (event.target as HTMLElement).dataset;
    if (options && onChange) {
      const option = options[parseInt(index, 10)];
      let result: any = option;
      if (multi) {
        if (Array.isArray(value)) {
          result = [...value, option];
        } else {
          result = [option];
        }

        this.setState({
          options: Select.filterOptions(options, result),
        });
      }
      onChange(result);
    }

    if (!multi) {
      this.setState({ showOptions: false });
      document.removeEventListener('click', this.onWindowClick);
    }
  }

  private onInputClick = () => {
    if (this.state.showOptions) { return; }
    document.addEventListener('click', this.onWindowClick);
    this.setState({ showOptions: true, input: '' });

    if (this.props.onSelectFocus) {
      this.props.onSelectFocus();
    }
  }

  private onInputContainerClick = () => {
    if (this.inputElement.current !== null && this.props.multi) {
      this.inputElement.current.focus();
    }
  }

  private onWindowClick = (event: MouseEvent) => {
    if (
      this.containerElement.current &&
      !this.containerElement.current.contains(event.target as Node)
    ) {
      this.setState({ showOptions: false, input: '' });
      document.removeEventListener('click', this.onWindowClick);
    }
  }

  private getTextValue() {
    const { value } = this.props;
    if (Array.isArray(value)) { return value.map(v => v.text).join(', '); }

    return get(value, 'text', '');
  }

  private onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.props.onInputChange) {
      return;
    }
    const { value } = e.target;
    this.setState({ input: value });
    clearTimeout(this.inputTimeoutId);
    this.inputTimeoutId = setTimeout(this.props.onInputChange, this.props.throttle, value);
  }

  private onOptionsScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const element = e.currentTarget;
    const toEnd = element.scrollHeight - element.clientHeight - element.scrollTop;
    const { optionsEndOffset = 0, onOptionsEnd } = this.props;
    if (onOptionsEnd && toEnd <= optionsEndOffset) {
      onOptionsEnd(this.state.input);
    }
  }
}
