import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addModal, closeModal } from 'store/actions/modal';
import { getDocument, resetDocument } from 'store/actions/document';
import Notification from 'components/Notification';
import DocumentViewer from 'components/DocumentViewer';
import { NotificationType, DocumentType } from 'utils';
import Modal from 'components/Modal';
import LoadingArea from 'components/shared/LoadingArea';

import styles from './DocumentModal.module.sass';

interface IDocumentProps {
  id: number;
  type: DocumentType.MENU | DocumentType.FOOTER | DocumentType.MEASUREMENT | DocumentType.AGREEMENT;
  name?: string;
  children?: React.ReactNode;
}

export default function DocumentModal(props: IDocumentProps) {
  const { id, type, name, children } = props;
  const {
    document,
    loading,
    error,
  } = useSelector((state: any) => state.document);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);

  useEffect(
    () => {
      dispatch(getDocument(id, DocumentType[type]));

      function onComponentUnmount() {
        dispatch(resetDocument());
      }

      return onComponentUnmount;
    },
    [id, type, dispatch],
  );

  useEffect(
    () => {
      function onCancelError() {
        setShowError(false);
        dispatch(closeModal());
      }

      if (error && !showError) {
        setShowError(true);
        dispatch(addModal((
          <Notification
            message={error}
            mode={NotificationType.error}
            cancelMessage={t('notification.cancel')}
            cancelCallback={onCancelError}
          />
        )));
      }
    },
    [error, showError, dispatch, t],
  );

  return (
    <Modal
      className={styles.container}
      title={name}
    >
      <DocumentViewer document={document} />

      {!loading && children}

      {loading && <LoadingArea fixed />}
    </Modal>
  );
}
