import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { buildStoreType, getConfig } from 'utils';
import ajax from 'api/ajax';
import i18n from 'locales/i18n';
import { GET_DOCUMENT, GET_DOCUMENTS, RESET_DOCUMENT } from 'store/types/document';
import {
  DOCUMENTS,
  DOCUMENT,
  interpolateString,
} from 'api/urls';
import * as prefixes from '../types/prefixes';

/**
 * Get single document by ID
 */
export function getDocument(documentId: number, type: string) {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, GET_DOCUMENT),
    });

    try {
      const { brand } = getConfig();
      const { data } = await ajax.get(interpolateString(DOCUMENT, { documentId, brand, type }));

      dispatch({
        data,
        type: buildStoreType(prefixes.success, GET_DOCUMENT),
      });
    } catch {
      dispatch({
        type: buildStoreType(prefixes.failure, GET_DOCUMENT),
        error: i18n.t('errors.internal'),
        showNotification: true,
      });
    }
  };
}

export function getDocuments() {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, GET_DOCUMENTS),
    });

    try {
      const { brand } = getConfig();
      const { data } = await ajax.get(interpolateString(DOCUMENTS, { brand }));

      dispatch({
        data,
        type: buildStoreType(prefixes.success, GET_DOCUMENTS),
      });
    } catch {
      dispatch({
        type: buildStoreType(prefixes.failure, GET_DOCUMENTS),
        error: i18n.t('errors.internal'),
        showNotification: true,
      });
    }
  };
}

export function resetDocument() {
  return {
    type: RESET_DOCUMENT,
  };
}
