import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/actions/modal';
import Icon from '../shared/Icon/Icon';
import styles from './Modal.module.sass';

interface IModalProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  onClose?: () => void;
}

/**
 * Modal dialog component.
 *
 * @param props External properties.
 */
export default function Modal(props: IModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  function onClose() {
    dispatch(closeModal());
  }

  return (
    <div className={classnames(styles.dialog, props.className)}>
      <div className={styles.header}>
        <h6 className={styles.title}>{props.title}</h6>
        <button onClick={props.onClose ? props.onClose : onClose}>
          <Icon name="close" />
          <span className={styles.text}>{t('general.close')}</span>
        </button>
      </div>
      <div className="modal-body">
        {props.children}
      </div>
    </div>
  );
}
