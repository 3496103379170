import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from 'components/Header/Header';
import ModalOverlay from 'components/ModalOverlay';
import { APP_INTERIM_PATH, getConfig } from 'utils';
import Product from 'pages/Product/loadable';
import Profile from 'pages/Profile/loadable';
import ChangeLog from 'pages/ChangeLog/loadable';
import { Signin, PasswordReset, EnterEmail } from 'pages/Auth/loadable';
import Footer from 'components/Footer';
import FullSizeContainer from 'components/FullSizeContainer';
import AuthProtector from './AuthProtector';
import RouterListener from './RouterListener';

import { PRODUCT_ROUTE_URL } from 'pages/Product/config';

/**
 * Application router
 */
export default function () {
  const { brand } = getConfig();

  return (
    <BrowserRouter>
      <Helmet>
        <title>PTP</title>
        <link rel="icon" href={`/${brand.toLowerCase()}/favicon.ico`} type="image/x-icon" />
      </Helmet>
      <>
        <RouterListener />
        <FullSizeContainer>
          <Switch>
            <Route path="/signin" component={Signin} exact />
            <Route path="/reset-password" component={PasswordReset} />
            <Route path="/forgot-password" component={EnterEmail} />

            <AuthProtector>
              <Header />

              <main className="main-content flex-no-shrink">
                <Switch>
                  <Route path="/profile" component={Profile} exact />

                  <Route path={PRODUCT_ROUTE_URL} component={Product} />
                  <Route path="/change-log" component={ChangeLog} />

                  <Redirect from="/" to={APP_INTERIM_PATH} />
                </Switch>
              </main>
            </AuthProtector>
          </Switch>
        </FullSizeContainer>

        <Footer />

        <ModalOverlay />
      </>
    </BrowserRouter>
  );
}
