import { AnyAction } from 'redux';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import { MODEL_LIST } from 'store/types/model';

export const initialState = {
  listLoading: false,
  list: [],
  error: undefined,
  page: undefined,
};

/**
 * Model reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, MODEL_LIST):
      return {
        ...state,
        listLoading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, MODEL_LIST):
      return {
        ...state,
        listLoading: false,
        list: action.payload.data,
        page: action.payload.page,
      };

    case buildStoreType(prefixes.failure, MODEL_LIST):
      return {
        ...state,
        listLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
