import axios from 'axios';
import { authorizeRequest, handleUnauthorizedResponse } from './interceptors';

const instance = axios.create({
  baseURL: '/api',
});

instance.interceptors.request.use(authorizeRequest);
instance.interceptors.response.use(undefined, handleUnauthorizedResponse);

export default instance;
