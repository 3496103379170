import React from 'react';
import classnames from 'classnames';

import styles from './Checkbox.module.sass';
import Icon from '../Icon/Icon';

export type ICheckboxProps = {
  className?: string,
  labelClassName?: string,
  inputClassName?: string,
  value?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  label?: string,
  name?: string,
  disabled?: boolean,
};

export default function (props: ICheckboxProps) {
  const {
    value,
    onChange,
    label,
    name,
    disabled,
    className,
    inputClassName,
    labelClassName,
  } = props;

  return (
    <label
      className={classnames(
        styles.container,
        className,
        {
          [styles.checked]: value,
          [styles.disabled]: disabled,
        },
      )}
    >
      {label && (
        <span className={classnames(styles.label, labelClassName)}>{label}</span>
      )}

      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={disabled ? undefined : onChange}
        disabled={disabled}
      />

      <div className={inputClassName}>
        <span className={classnames(styles.checkbox, 'input')}>
          {value && <Icon name="checkmark-released" />}
        </span>
      </div>
    </label>
  );
}
