import { AnyAction } from 'redux';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import {
  SAMPLE_LIST,
  SAMPLE_SHIP,
  SAMPLE_SAVE_FOR_SHIP,
  SAMPLE_ASSIGN,
  SAMPLE_RESET_ERROR,
  SAMPLE_START_TEST,
} from 'store/types/sample';

export const initialState = {
  loading: false,
  list: [],
  testers: {},
  error: undefined,
  assignLoading: false,
  startTestLoading: false,
  samplesForShip: [],
  page: undefined,
};

/**
 * Sample reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, SAMPLE_LIST):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, SAMPLE_LIST):
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        page: action.payload.page,
      };

    case buildStoreType(prefixes.failure, SAMPLE_LIST):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SAMPLE_SAVE_FOR_SHIP:
      return {
        ...state,
        samplesForShip: action.sampleIds,
      };

    case buildStoreType(prefixes.requesting, SAMPLE_SHIP):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, SAMPLE_SHIP):
      return {
        ...state,
        loading: false,
      };

    case buildStoreType(prefixes.failure, SAMPLE_SHIP):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case buildStoreType(prefixes.requesting, SAMPLE_ASSIGN):
      return {
        ...state,
        assignLoading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, SAMPLE_ASSIGN):
      return {
        ...state,
        assignLoading: false,
        list: state.list.map((item: any) => {
          return action.sample.testReportId === item.testReportId
            ? action.sample
            : item;
        }),
      };

    case buildStoreType(prefixes.failure, SAMPLE_ASSIGN):
      return {
        ...state,
        assignLoading: false,
        error: action.error,
      };

    case buildStoreType(prefixes.requesting, SAMPLE_START_TEST):
      return {
        ...state,
        startTestLoading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, SAMPLE_START_TEST):
      return {
        ...state,
        startTestLoading: false,
        list: state.list.filter((item: any) => action.reportId !== item.testReportId),
      };

    case buildStoreType(prefixes.failure, SAMPLE_START_TEST):
      return {
        ...state,
        startTestLoading: false,
        error: action.error,
      };

    case SAMPLE_RESET_ERROR:
      return {
        ...state,
        error: undefined,
      };

    default:
      return state;
  }
};
