import { AnyAction } from 'redux';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import {
  UPLOAD_ATTACHMENT,
  REMOVE_NEW_ATTACHMENT,
  CLEAR_ATTACHMENT,
  ADD_REMOVED_ATTACHMENT,
  ATTACHMENT_PROPERTIES,
} from 'store/types/attachment';

export const initialState: any = {
  added: {},
  removed: undefined,
  properties: undefined,
};

/**
 * Attachment reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, UPLOAD_ATTACHMENT):
      return {
        ...state,
        added: {
          ...state.added,
          [action.id]: {
            loading: true,
            file: action.file,
          },
        },
      };

    case buildStoreType(prefixes.success, UPLOAD_ATTACHMENT): {
      const files: { [key: number]: any } = state.added;
      if (files[action.id]) {
        files[action.id] = {
          ...files[action.id],
          loading: false,
          attachmentId: action.attachmentId,
        };
      }

      return {
        ...state,
        added: {
          ...files,
        },
      };
    }

    case buildStoreType(prefixes.failure, UPLOAD_ATTACHMENT): {
      const files: { [key: number]: any } = state.added;
      if (files[action.id]) {
        files[action.id] = {
          ...files[action.id],
          loading: false,
          error: action.error,
        };
      }

      return {
        ...state,
        added: {
          ...files,
        },
      };
    }

    case REMOVE_NEW_ATTACHMENT: {
      const { [action.id]: removedFile, ...files } = state.added as any;

      return {
        ...state,
        added: {
          ...files,
        },
      };
    }

    case CLEAR_ATTACHMENT:
      return {
        ...state,
        added: {},
        removed: undefined,
      };

    case ADD_REMOVED_ATTACHMENT: {
      const removed = state.removed || new Set();
      removed.add(action.id);

      return {
        ...state,
        removed,
      };
    }

    case ATTACHMENT_PROPERTIES:
      return {
        ...state,
        properties: action.data,
      };

    default:
      return state;
  }
};
