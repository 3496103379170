import React from 'react';
import { useTranslation } from 'react-i18next';
import { signOut } from 'store/actions/auth';
import { useDispatch } from 'react-redux';
import { replaceModal } from 'store/actions/modal';
import Notification from 'components/Notification';
import { NotificationType } from 'utils';

import styles from './Menu.module.sass';

/**
 * Logout component
 * @param props an oject with parameter t as translations method
 */
export default function () {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function onSignOutConfirm() {
    dispatch(signOut());
  }

  function onButtonClick() {
    dispatch(replaceModal((
      <Notification
        mode={NotificationType.warning}
        message={t('logout.warning')}
        cancelMessage={t('logout.cancel')}
        confirmMessage={t('logout.proceed')}
        confirmCallback={onSignOutConfirm}
      />
    )));
  }

  return (
    <button className={styles.link} onClick={onButtonClick}>
      {t('logout.logout')}
    </button>
  );
}
