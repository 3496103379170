import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { buildStoreType, switchLang, getDevModeFeatures } from 'utils';
import { interpolateString, TESTER_INFO, TESTER_SHOW_WTA } from 'api/urls';
import ajax from 'api/ajax';
import i18n from 'locales/i18n';
import * as prefixes from 'store/types/prefixes';
import { GET_TESTER_INFO, GET_TESTER_WTA } from 'store/types/userInfo';
import { getDocuments } from './document';

/**
 * User info action
 */
export function getUserInfo() {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, GET_TESTER_INFO),
    });

    try {
      const { data } = await ajax.get(TESTER_INFO);

      if (getDevModeFeatures().localization) {
        switchLang(data.preferredLanguageCode, () => {
          dispatch(getDocuments());
        });
      }

      dispatch({
        data,
        type: buildStoreType(prefixes.success, GET_TESTER_INFO),
      });
    } catch {
      dispatch({
        type: buildStoreType(prefixes.failure, GET_TESTER_INFO),
        error: i18n.t('errors.internal'),
      });
    }
  };
}

export function getWTAStatus(testerId: number) {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, GET_TESTER_WTA),
    });

    try {
      const response = await ajax.get(interpolateString(TESTER_SHOW_WTA, {
        testerId,
      }));

      dispatch({
        showWTA: response.data.showWTA,
        type: buildStoreType(prefixes.success, GET_TESTER_WTA),
      });
    } catch {
      dispatch({
        type: buildStoreType(prefixes.failure, GET_TESTER_WTA),
        error: i18n.t('errors.internal'),
      });
    }
  };
}
