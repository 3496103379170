import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import get from 'lodash/get';
import ajax from 'api/ajax';
import { buildStoreType } from 'utils';
import { RELEASES, RELEASES_NEW } from 'api/urls';
import * as prefixes from 'store/types/prefixes';
import i18n from 'locales/i18n';
import { RELEASES_GET_LIST, RELEASES_GET_NEW, RELEASES_MARK_AS_READ, RELEASES_RESET_ERRORS } from 'store/types/releases';

/**
 * Get list of new releases
 */
export function getNewReleases(page = 0) {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, RELEASES_GET_NEW),
    });

    try {
      const { data } = await ajax.get(RELEASES_NEW, {
        params: { page },
      });

      dispatch({
        new: get(data, '_embedded.content[0]'),
        page: data.page,
        type: buildStoreType(prefixes.success, RELEASES_GET_NEW),
      });
    } catch {
      dispatch({
        type: buildStoreType(prefixes.failure, RELEASES_GET_NEW),
        error: i18n.t('errors.internal'),
      });
    }
  };
}

/**
 * Get list of releases
 */
export function getReleases(page = 0, size = 10) {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, RELEASES_GET_LIST),
    });

    try {
      const { data } = await ajax.get(RELEASES, {
        params: {
          page,
          size,
        },
      });

      dispatch({
        list: get(data, '_embedded.content', []),
        page: data.page,
        type: buildStoreType(prefixes.success, RELEASES_GET_LIST),
      });
    } catch {
      dispatch({
        type: buildStoreType(prefixes.failure, RELEASES_GET_LIST),
        error: i18n.t('errors.internal'),
      });
    }
  };
}

/**
 * Post releases as read
 */
export function postNewReleases() {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: buildStoreType(prefixes.requesting, RELEASES_MARK_AS_READ),
    });

    try {
      await ajax.post(RELEASES_NEW);

      dispatch({
        type: buildStoreType(prefixes.success, RELEASES_MARK_AS_READ),
      });
    } catch {
      dispatch({
        type: buildStoreType(prefixes.failure, RELEASES_MARK_AS_READ),
      });
    }
  };
}

export function resetErrors() {
  return { type: RELEASES_RESET_ERRORS };
}
