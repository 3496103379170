import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import Modal from 'components/Modal';
import ChangeLogViewer from 'components/ChangeLog/ChangeLogViewer';
import Button from 'components/shared/Button';
import LoadingArea from 'components/shared/LoadingArea';
import { postNewReleases, getNewReleases } from 'store/actions/releases';
import { closeModal } from 'store/actions/modal';

import styles from './ChangeLogModal.module.sass';

export default function ChangeLogModal() {
  const { new: newFeatures } = useSelector((state: any) => state.releases);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { totalPages = 0, number = 0 } = get(newFeatures, 'page', {});

  function closeHandler() {
    dispatch(postNewReleases());
    dispatch(closeModal());
  }

  function nextFeature() {
    if (number + 1 <= newFeatures.length) {
      return;
    }
    dispatch(getNewReleases(number + 1));
  }

  const showNextButton = number + 1 < totalPages;

  return (
    <Modal onClose={closeHandler} title={t('changeLog.popUpHeader')}>
      {newFeatures.content && (
        <ChangeLogViewer features={[newFeatures.content]} />
      )}

      {showNextButton && (
        <div className={styles.controls}>
          <Button
            value={t('changeLog.next')}
            onClick={nextFeature}
          />
        </div>
      )}

      {newFeatures.loading && <LoadingArea fixed />}
    </Modal>
  );
}
