/**
 * Auth Action type.
 */
export const SIGN_IN: string = 'SIGN_IN';
export const SIGN_OUT: string = 'SIGN_OUT';
export const SEND_EMAIL: string = 'SEND_EMAIL';
export const CHANGE_PASSWORD: string = 'CHANGE_PASSWORD';
export const RESET_STORE: string = 'RESET_STORE';
export const CHECK_TOKEN: string = 'CHECK_TOKEN';
export const RESET_ERRORS: string = 'RESET_ERRORS';
export const REFRESH_TOKEN: string = 'REFRESH_TOKEN';
