import React from 'react';
import classnames from 'classnames';

import styles from './Textbox.module.sass';

interface ITextboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  value?: string;
  type?: string;
  placeholder?: string;
  name?: string;
  label?: string;
  horizontal?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  postfix?: string;
  required?: boolean;
}

function Textbox(props: ITextboxProps) {
  const {
    className,
    inputClassName,
    value,
    type = 'text',
    placeholder,
    onChange,
    name,
    id,
    onClick,
    label,
    labelClassName,
    horizontal,
    onBlur,
    disabled,
    postfix,
    onFocus,
    required,
    ...inputProps
  } = props;

  return (
    <label
      className={classnames(styles.container, className, {
        [styles.horizontal]: horizontal,
        [styles.disabled]: disabled,
      })}
    >
      {label && (
        <span className={classnames(styles.label, labelClassName)}>
          {label}

          {required && (
          <>
            &nbsp;
            <span className={styles.required}>*</span>
          </>
        )}
        </span>
      )}

      <div className={classnames(inputClassName, styles.input_container)}>
        <input
          id={id}
          className={classnames(styles.input, 'input')}
          value={value}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={disabled ? undefined : onChange}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          {...inputProps}
        />

        {postfix && <span className={classnames(styles.postfix, 'postfix')}>{postfix}</span>}
      </div>
    </label>
  );
}

export default Textbox;
