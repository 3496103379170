import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import lngDetector from 'i18next-browser-languagedetector';
import {
  capitalize,
  isEmpty,
  toUpper,
  startCase,
} from 'lodash';

/**
 * Cached translations.
 */
const cached: any = {};

/**
 * Upper case translation prefix.
 */
const UPPERCASE_TRANSLATION_PREFIX = 'ut';

/**
 * Capitalize case translation prefix.
 */
const CAPITALIZE_TRANSLATION_PREFIX = 'ct';

/**
 * Start case translation prefix.
 */
const START_CASE_TRANSLATION_PREFIX = 'st';

/**
 * Upper case translation match.
 */
const UPPERCASE_TRANSLATION_MATCH = createMatchRegExp(UPPERCASE_TRANSLATION_PREFIX);

/**
 * Capitalize case translation match.
 */
const CAPITALIZE_TRANSLATION_MATCH = createMatchRegExp(CAPITALIZE_TRANSLATION_PREFIX);

/**
 * Start case translation match.
 */
const START_CASE_TRANSLATION_MATCH = createMatchRegExp(START_CASE_TRANSLATION_PREFIX);

/**
 * Create translation match regexp.
 *
 * @param prefix Match prefix.
 * @returns RegExp.
 */
function createMatchRegExp(prefix: string): RegExp {
  return new RegExp(`\\$${prefix}\\(+([^)]*)\\)`, 'g');
}

/**
 * Format string using formatter function.
 *
 * @param str String to format.
 * @param regexp Replace regexp.
 * @param formatter Formatter function.
 */
function translateInCase(str: any, regexp: any, formatter: any) {
  return str.replace(regexp, (chunk: any, group: any) => formatter(i18n.t(group)));
}

i18n
.use(XHR)
.use(lngDetector)
.use(initReactI18next)
.use({
  type: 'postProcessor',
  name: 'inlineFormat',
  process(value: any, key: any, input: any) {
    let output = value || '';

    if (cached[key] && isEmpty(input)) {
      output = cached[key];
    } else {
      // TODO-js: Separate and test
      if (output.indexOf(CAPITALIZE_TRANSLATION_PREFIX) >= 0) {
        output = translateInCase(output, CAPITALIZE_TRANSLATION_MATCH, capitalize);
      }

      if (output.indexOf(UPPERCASE_TRANSLATION_PREFIX) >= 0) {
        output = translateInCase(output, UPPERCASE_TRANSLATION_MATCH, toUpper);
      }

      if (output.indexOf(START_CASE_TRANSLATION_PREFIX) >= 0) {
        output = translateInCase(output, START_CASE_TRANSLATION_MATCH, startCase);
      }

      cached[key] = output;
    }

    return output;
  },
});

/**
 * Load external I18n file.
 *
 * @param url File url to load.
 * @param options Load options.
 * @param callback Callback on already loaded i18n file.
 */
function bundleLoad(url: string, options: any, callback: Function): void {
  // @ts-ignore
  Promise.allSettled([
    // tslint:disable-next-line:space-in-parens
    import(`./${url}/common.json`/* webpackChunkName: 'i18n/[request]/common' */),
    // tslint:disable-next-line:space-in-parens
    import(`./${url}/error.json`/* webpackChunkName: 'i18n/[request]/error' */),
  ])
    .then((modules: any[]) => modules.filter((module: any) => 'fulfilled' === module.status))
    .then((modules: any[]) => {
      const attributes = modules.reduce(
        (acc, { value }) => {
          return { ...acc, ...value['default'] };
        },
        {});

      callback(attributes, { status: '200' });
    })
    .catch(() => {
      callback(null, { status: '404' });
    });
}

i18n
.init({
  fallbackLng: 'en',
  load: 'languageOnly',
  backend: {
    loadPath: '{{lng}}',
    parse: (data: any) => data,
    ajax: bundleLoad,
  },
  postProcess: [
    'inlineFormat',
  ],
});

export default i18n;
