import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { closeModal } from 'store/actions/modal';
import { NotificationType } from 'utils';

import styles from './Notification.module.sass';

interface INotificationProps {
  message: string;
  mode: NotificationType.warning | NotificationType.error;
  cancelMessage?: string;
  confirmMessage?: string;
  cancelCallback?: () => void;
  confirmCallback?: () => void;
}

export default function Notification(props: INotificationProps) {
  const {
    message,
    mode,
    cancelMessage,
    confirmMessage,
    cancelCallback,
    confirmCallback,
  } = props;
  const dispatch = useDispatch();

  function onCancel() {
    dispatch(closeModal());

    if (cancelCallback) {
      cancelCallback();
    }
  }

  function onConfirm() {
    dispatch(closeModal());

    if (confirmCallback) {
      confirmCallback();
    }
  }

  return (
    <div className={classnames(styles.card, styles[mode])}>
      <p className={styles.message}>{message}</p>

      {confirmMessage && (
        <button
          type="button"
          className={styles.confirm}
          onClick={onConfirm}
        >
          {confirmMessage}
        </button>
      )}

      {cancelMessage && (
        <button
          type="button"
          className={styles.cancel}
          onClick={onCancel}
        >
          {cancelMessage}
        </button>
      )}
    </div>
  );
}
