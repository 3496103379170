import React from 'react';
import classnames from 'classnames';

import styles from './LoadingArea.module.sass';

interface ILoadingAreaProps {
  className?: string;
  fixed?: boolean;
}

export default function LoadingArea({ className, fixed }: ILoadingAreaProps) {
  return (
    <div
      className={classnames(styles.container, className, {
        [styles.fixed]: fixed,
      })}
    >
      <div />
      <div />
      <div />
    </div>
  );
}
