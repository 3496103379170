export const READY: string = 'PRODUCT_LIST_READY';
export const ERROR: string = 'PRODUCT_FETCH_FAILURE';
export const FETCH_START: string = 'PRODUCT_FETCH';
export const RESET: string = 'PRODUCT_RESET';
export const BLOCK: string = 'PRODUCT_BLOCK';
export const STATISTIC: string = 'PRODUCT_AMOUNT';
export const PROCESS: string = 'PRODUCT_PROCESSING';
export const SHIP_PRODUCT: string = 'SHIP_PRODUCT';
export const SHIP_PRODUCT_RESET: string = 'SHIP_PRODUCT_RESET';
export const COMMENTS_FETCH_START: string = 'COMMENTS_FETCHING';
export const COMMENTS_ERROR: string = 'COMMENTS_ERROR';
export const COMMENTS_READY: string = 'COMMENTS_SUCCESS';
export const PRODUCT_LIST: string = 'PRODUCT_LIST';
export const PRODUCT_LIFECYCLE: string = 'PRODUCT_LIFECYCLE';
export const PRODUCT_RESET_ERROR: string = 'PRODUCT_RESET_ERROR';
