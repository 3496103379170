import React from 'react';

import styles from './icon.module.sass';

type IIconProps = {
  text?: String,
  name: String,
};

/**
 * Icon.
 */
export default function (props: IIconProps) {
  return (
    <span className={styles.iconRoot}>
      <span className="text">
        {props.text}
      </span>
      <span className={`icon ds-icon ds-icon--${props.name} tst-icon`} />
    </span>
  );
}
