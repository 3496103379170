import React from 'react';

import styles from './DocumentViewer.module.sass';

interface IDocumentViewerProps {
  document: string;
}

export default function ({ document }: IDocumentViewerProps) {
  return (
    <div className={styles.content}>
      <div
        dangerouslySetInnerHTML={{
          __html: document,
        }}
      />
    </div>
  );
}
