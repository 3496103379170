import { AnyAction } from 'redux';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import { FETCH_IMAGE } from 'store/types/image';

export const initialState = {
  data: {},
  error: undefined,
};

/**
 * Image reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, FETCH_IMAGE):
      return {
        ...state,
        data: {
          ...state.data,
          [action.src]: {
            loading: true,
          },
        },
        error: undefined,
      };

    case buildStoreType(prefixes.success, FETCH_IMAGE):
      return {
        ...state,
        data: {
          ...state.data,
          [action.src]: {
            image: action.image,
          },
        },
      };

    case buildStoreType(prefixes.failure, FETCH_IMAGE):
      return {
        ...state,
        data: {
          ...state.data,
          [action.src]: undefined,
        },
        error: action.error,
      };

    default:
      return state;
  }
};
