import { AxiosRequestConfig, AxiosError } from 'axios';
import { AUTH_TOKEN_KEY, APP_SIGN_IN_PATH, clearAuthData, getSavedLang } from 'utils';

/**
 * Request interceptor for adding Authorization token to the request's config
 * If there is no JWT in localStorage -> redirect to login page
 * @param config request config for a request
 */
export function authorizeRequest(config: AxiosRequestConfig) {
  const token: string | null = window.localStorage.getItem(AUTH_TOKEN_KEY);
  config.headers.common['Accept-Language'] = getSavedLang();

  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }

  return config;
}

/**
 * Response error interceptor for check if user's JWT token is invalid
 * then clear localStorage and redirect to login page
 * @param error axios error object
 */
export function handleUnauthorizedResponse(error: AxiosError) {
  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403) &&
    window.location.pathname !== APP_SIGN_IN_PATH
  ) {
    clearAuthData();
    // const rememberPath = window.location.pathname;
    // window.location.replace(`${APP_SIGN_IN_PATH}#remember-path=${rememberPath}`);
    window.location.replace(APP_SIGN_IN_PATH);
  }

  return Promise.reject(error);
}
