// These must be the first lines in src/index.js
import 'core-js/es/object';
import 'core-js/es/promise';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/string/pad-start';
import 'core-js/es/string/includes';
import 'core-js/es/array/from';
import 'core-js/es/array/includes';
import 'core-js/es/array/find';

// somewhy polyfill for Promise.allSettled doesn't work for IE
// @ts-ignore
Promise.allSettled = (promises: Promise<any>[]) => {
  const wrappedPromises = promises.map(p => Promise.resolve(p)
      .then(
          val => ({ status: 'fulfilled', value: val }),
          err => ({ status: 'rejected', reason: err }),
      ),
  );

  return Promise.all(wrappedPromises);
};

/* eslint-disable import/first */
import './index.sass';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './locales/i18n';
import { switchLang, availableLangs, getDevModeFeatures } from 'utils';
/* eslint-enable import/first */

// Render application.
new Promise((resolve) => {
  if (process.env.NODE_ENV === 'production') {
    resolve(axios.get('/config'));
  } else {
    const {
      REACT_APP_BRAND: brand,
      REACT_APP_MATOMO_SITE_ID: matomoId,
      REACT_APP_PRODUCT_TTL: productTTL,
      REACT_APP_FEATURE_LOCALIZATION: featureLocalization,
    } = process.env;
    resolve({
      data: {
        brand,
        matomoId,
        productTTL,
        featureLocalization,
      },
    });
  }
})
  .then(({ data }: any) => {
    (window as any).application = data;

    // Reset predefined lang if localization is disabled
    if (!getDevModeFeatures().localization) {
      switchLang(availableLangs.english);
    }

    // Matomo script
    const container = document.getElementsByTagName('body')[0];
    if (!data.matomoId || !container) { return; }

    (window as any)._paq = (window as any)._paq || [];
    const { _paq } = (window as any);

    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    const matomoUrl = '//matomo.techmon.3stripes.net/';
    _paq.push(['setTrackerUrl', `${matomoUrl}matomo.php`]);
    _paq.push(['setSiteId', data.matomoId]);

    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.async = true;
    newScript.defer = true;
    newScript.src = `${matomoUrl}matomo.js`;

    container.prepend(newScript);
    // Matomo script end
  })
  .catch(() => {
    console.error('Config serve is not available');
  })
  .finally(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
