import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Notification from 'components/Notification';
import ChangeLogModal from 'components/ChangeLog/ChangeLogModal';
import { getUserInfo, getWTAStatus } from 'store/actions/userInfo';
import { addModal } from 'store/actions/modal';
import { getNewReleases } from 'store/actions/releases';
import { NotificationType } from 'utils';
import HeaderMobile from './Header.mbl';

import styles from './Header.module.sass';

let showNewFeatures = false;

/**
 * Header.
 */
export default function () {
  const dispatch = useDispatch();
  const userInfoError = useSelector((state: any) => state.userInfo.error);
  const userInfo = useSelector((state: any) => state.userInfo.data);
  const newFeatures = useSelector((state: any) => state.releases.new);

  const { t } = useTranslation();
  const memoizedFetch = useCallback(
    () => {
      dispatch(getUserInfo());
    },
    [dispatch],
  );

  useEffect(
    () => {
      memoizedFetch();
    },
    [memoizedFetch],
  );

  useEffect(
    () => {
      if (!showNewFeatures && newFeatures.page && newFeatures.page.totalElements > 0) {
        showNewFeatures = true;
        dispatch(addModal(<ChangeLogModal />));
      }
    },
    [newFeatures, dispatch],
  );

  useEffect(
    () => {
      dispatch(getNewReleases());
    },
    [dispatch],
  );

  useEffect(
    () => {
      if (!userInfo) return;

      dispatch(getWTAStatus(userInfo.testerId));
    },
    [userInfo, dispatch],
  );

  if (userInfoError) {
    dispatch(addModal((
      <Notification
        mode={NotificationType.error}
        message={userInfoError}
        cancelMessage={t('general.tryAgain')}
        cancelCallback={memoizedFetch}
      />
    )));
  }

  return (
    <header className={styles.container}>
      <HeaderMobile />
    </header>
  );
}
