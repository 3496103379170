import React, { Suspense } from 'react';

interface ILazyLoadWrapperProps {
  children: React.ReactNode;
  fallback?: string | React.ReactNode;
}

export default function LazyLoadWrapper(props: ILazyLoadWrapperProps) {
  const { children, fallback = 'Loading...' } = props;

  return (
    <Suspense fallback={fallback}>
      {children}
    </Suspense>
  );
}
