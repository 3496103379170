import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/actions/modal';

import styles from './MenuWrapper.module.sass';
import Menu from '..';

export default function () {
  const dispatch = useDispatch();
  const [shouldClose, setClosing] = useState(false);

  function triggerClose() { setClosing(true); }

  useEffect(
    () => {
      if (shouldClose) {
        dispatch(closeModal());
      }
    },
    [shouldClose, dispatch],
  );

  return (
    <div
      className={classnames(styles.container, {
        [styles.closing]: shouldClose,
      })}
      onClick={triggerClose}
    >
      <Menu triggerClose={triggerClose} closing={shouldClose} />
    </div>
  );
}
