import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import styles from './ModalOverlay.module.sass';

export default function Overlay() {
  const [component] = useSelector((state: any) => state.modal.components);
  if (!component) { return null; }

  return (
    <div className={classnames(styles.container)}>
      {component}
    </div>
  );
}
