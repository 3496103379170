import React from 'react';
import classnames from 'classnames';

import styles from './Spinner.module.sass';

type ISpinnerProps = {
  className?: string,
};

export default function (props: ISpinnerProps) {
  return (
    <span className={classnames(styles.container, props.className)} />
  );
}
