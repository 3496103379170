import { AnyAction } from 'redux';
import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import { GET_DOCUMENT, GET_DOCUMENTS, RESET_DOCUMENT } from 'store/types/document';

export const initialState = {
  loading: false,
  error: undefined,
  document: undefined,
  documents: {},
  documentsLoading: false,
};

/**
 * Documents reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    // getting document by id
    case buildStoreType(prefixes.requesting, GET_DOCUMENT):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, GET_DOCUMENT):
      return {
        ...state,
        loading: false,
        document: action.data,
      };

    case buildStoreType(prefixes.failure, GET_DOCUMENT):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // getting list of document data
    case buildStoreType(prefixes.requesting, GET_DOCUMENTS):
      return {
        ...state,
        documentsLoading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, GET_DOCUMENTS):
      return {
        ...state,
        documentsLoading: false,
        documents: action.data,
      };

    case buildStoreType(prefixes.failure, GET_DOCUMENTS):
      return {
        ...state,
        documentsLoading: false,
        error: action.error,
      };

    case RESET_DOCUMENT:
      return {
        ...state,
        document: undefined,
      };

    default:
      return state;
  }
};
