import { useEffect } from 'react';
import useRouter from 'use-react-router';

export default () => {
  const { history } = useRouter();

  useEffect(
    () => {
      const unsub = history.listen((location) => {
        const { _paq } = (window as any);
        if (!_paq) { return unsub; }

        try {
          _paq.push(['setCustomUrl', location.pathname]);
          _paq.push(['setDocumentTitle', document.title]);
          _paq.push(['trackPageView']);
        } catch {
          console.log('Wrong analytic function');
        }
      });

      return unsub;
    },
    [history],
  );

  return null;
};
