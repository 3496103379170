import { AnyAction } from 'redux';
import get from 'lodash/get';

import { buildStoreType } from 'utils';
import * as prefixes from 'store/types/prefixes';
import { GET_PROFILE, UPDATE_PROFILE, GET_PROFILE_DROPDOWN } from 'store/types/profile';

export const initialState = {
  loading: false,
  profile: null,
  updating: false,
  dropdownValues: {},
  error: undefined,
  validation: undefined,
};

/**
 * Profile reducer.
 *
 * @param state State.
 * @param action Action.
 */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case buildStoreType(prefixes.requesting, GET_PROFILE):
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case buildStoreType(prefixes.success, GET_PROFILE):
      return {
        ...state,
        loading: false,
        profile: action.profile,
      };

    case buildStoreType(prefixes.failure, GET_PROFILE):
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case buildStoreType(prefixes.requesting, UPDATE_PROFILE):
      return {
        ...state,
        updating: true,
        loading: true,
        validation: undefined,
        error: undefined,
      };

    case buildStoreType(prefixes.success, UPDATE_PROFILE):
      return {
        ...state,
        updating: false,
        loading: false,
        profile: action.profile,
      };

    case buildStoreType(prefixes.failure, UPDATE_PROFILE):
      return {
        ...state,
        updating: false,
        loading: false,
        error: action.error,
        validation: action.validation,
      };

    case buildStoreType(prefixes.requesting, GET_PROFILE_DROPDOWN): {
      const dropdownValues: any = {
        ...state.dropdownValues,
      };
      const field = get(dropdownValues, action.field, {});
      dropdownValues[action.field] = {
        ...field,
        loading: true,
      };

      return {
        ...state,
        error: undefined,
        dropdownValues: {
          ...dropdownValues,
        },
      };
    }

    case buildStoreType(prefixes.success, GET_PROFILE_DROPDOWN): {
      const dropdownValues: any = {
        ...state.dropdownValues,
      };
      const field = get(dropdownValues, action.field, {});
      const number = get(field, 'page.number', 0);
      const newNumber = get(action.page, 'number', 0);
      let values = action.data;
      if (newNumber > number) {
        values = [...get(field, 'values', []), ...values];
      }
      dropdownValues[action.field] = {
        ...field,
        values,
        loading: false,
        page: action.page,
      };

      return {
        ...state,
        dropdownValues: {
          ...dropdownValues,
        },
      };
    }

    case buildStoreType(prefixes.failure, GET_PROFILE_DROPDOWN): {
      const dropdownValues: any = {
        ...state.dropdownValues,
      };
      const field = get(dropdownValues, action.field, {});
      dropdownValues[action.field] = {
        ...field,
        loading: false,
        error: action.error,
      };

      return {
        ...state,
        dropdownValues: {
          ...dropdownValues,
        },
      };
    }

    default:
      return state;
  }
};
