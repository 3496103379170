import { combineReducers } from 'redux';

import activity from './activity';
import auth from './auth';
import modal from './modal';
import product from './product';
import profile from './profile';
import document from './document';
import facilityManager from './facilityManager';
import model from './model';
import sample from './sample';
import userInfo from './userInfo';
import image from './image';
import dailyLogs from './dailyLogs';
import releases from './releases';
import attachment from './attachment';

/**
 * Combine all reducers
 */
export default combineReducers({
  activity,
  auth,
  modal,
  product,
  profile,
  document,
  facilityManager,
  model,
  sample,
  userInfo,
  image,
  dailyLogs,
  releases,
  attachment,
});
