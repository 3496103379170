import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { AUTH_TOKEN_KEY, APP_SIGN_IN_PATH } from 'utils';
import { refreshToken } from 'store/actions/auth';

type IAuthProtectorProps = {
  children?: ReactNode,
};

let isAuthRefreshSet = false;

export default function AuthProtector({ children }: IAuthProtectorProps): any {
  const dispatch = useDispatch();

  if (!window.localStorage.getItem(AUTH_TOKEN_KEY)) {
    // const rememberPath = window.location.pathname;
    // window.location.replace(`${APP_SIGN_IN_PATH}#remember-path=${rememberPath}`);
    window.location.replace(APP_SIGN_IN_PATH);

    return null;
  }

  if (!isAuthRefreshSet) {
    isAuthRefreshSet = true;
    setInterval(
      () => {
        dispatch(refreshToken());
      },
      // 50 mins
      3000000,
    );

    // Refresh token after restart browser
    setTimeout(
      () => {
        dispatch(refreshToken());
      },
      // 1 min
      60000,
    );
  }

  return children;
}
